import service from "@/plugins/request";

// 游戏详情
export function apiGameInfo(params) {
  return service({
    url: "/v1/game/detail",
    method: "get",
    params: params
  });
}

// wiki详情编辑
export function apiWikiEdit(params) {
  return service({
    url: "/v1/protected/game/edit",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 友情链接编辑
export function apiWikiEditFriendLink(params) {
  return service({
    url: "/v1/protected/game/config",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// wikiBanner
export function apiGetRecommend(params) {
  return service({
    url: "/v1/protected/slider/list",
    method: "get",
    data: params
  });
}

// 编辑wikiBanner
export function apiEditRecommend(params) {
  return service({
    url: "/v1/protected/slider/edit",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 创建游戏
export function apiGameAdd(params) {
  return service({
    url: "/v1/protected/game/add",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 删除游戏
export function apiGameDel(params) {
  return service({
    url: "/v1/protected/game/del",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 游戏列表
export function apiGamePageList(params) {
  return service({
    url: "/v1/game/pageList",
    method: "get",
    params: params
  });
}

// 游戏版本列表
export function apiGameTimeLineList(params) {
  return service({
    url: "/v1/protected/gameTimeLine/list",
    method: "get",
    params: params
  });
}

// 编辑游戏版本
export function apiGameTimeLineEdit(params) {
  let url = "/v1/protected/gameTimeLine/add";
  if (params.id) {
    url = "/v1/protected/gameTimeLine/edit";
  }
  return service({
    url,
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 删除游戏版本
export function apiGameTimeLineDel(params) {
  return service({
    url: "/v1/protected/gameTimeLine/del",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 开通wiki
export function apiGameWikiCreate(params) {
  return service({
    url: "/v1/protected/wiki/create",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 开通wiki
export function apiGameStoreArea(params) {
  return service({
    url: "/v1/game/storeArea",
    method: "get"
  });
}

// wiki游戏详情
export function apiWikiGameInfo(params) {
  return service({
    url: "/v1/gameInfo/detail",
    method: "get",
    params: params
  });
}
// 游戏列表
export function apiGameList(params) {
  return service({
    url: "/v1/game/list",
    method: "get",
    params: params
  });
}

// 游戏视频观看
export function apiGameVideoAddPlayNum(params) {
  return service({
    url: "/v1/gameVideo/addPlayNum",
    method: "get",
    params: params
  });
}

// 云游戏绑定列表
export function apiCloudGameBindList(params) {
  return service({
    url: "/v1/protected/game/cloud-game/page-list",
    method: "get",
    params: params
  });
}

// 云游戏绑定
export function apiCloudGameBind(params) {
  return service({
    url: "/v1/protected/game/cloud-game/bind",
    method: "post",
    data: params
  });
}

// 云游戏解绑
export function apiCloudGameUnbind(params) {
  return service({
    url: "/v1/protected/game/cloud-game/unbind",
    method: "post",
    data: params
  });
}

// 游戏别名重定向列表
export function apiGameAliasRedirectList(params) {
  return service({
    url: "/v1/protected/gameAlias/pageList",
    method: "get",
    params: params
  });
}

// 游戏别名重定向添加
export function apiGameAliasRedirectAdd(params) {
  return service({
    url: "/v1/protected/gameAlias/add",
    method: "post",
    data: params
  });
}

// 游戏别名重定向删除
export function apiGameAliasRedirectDelete(params) {
  return service({
    url: "/v1/protected/gameAlias/delete",
    method: "post",
    data: params
  });
}

// 表情包分组详情
export function apiEmojiGroupQueryList(params) {
  return service({
    url: "/v1/emojiGroup/queryList",
    method: "get",
    params
  });
}

// 表情包分组列表
export function apiEmojiGroupPageList(params) {
  return service({
    url: "/v1/protected/emojiGroup/page-list",
    method: "post",
    data: params
  });
}

// 添加表情包分组
export function apiEmojiGroupAdd(params) {
  return service({
    url: "/v1/protected/emojiGroup/add",
    method: "post",
    data: params
  });
}

// 编辑表情包分组
export function apiEmojiGroupEdit(params) {
  return service({
    url: "/v1/protected/emojiGroup/edit",
    method: "post",
    data: params
  });
}

// 删除表情包分组
export function apiEmojiGroupDelete(params) {
  return service({
    url: "/v1/protected/emojiGroup/delete",
    method: "post",
    data: params
  });
}

// 表情包列表
export function apiEmojiPageList(params) {
  return service({
    url: "/v1/protected/emoji/page-list",
    method: "post",
    data: params
  });
}

// 添加表情包
export function apiEmojiAdd(params) {
  return service({
    url: "/v1/protected/emoji/add",
    method: "post",
    data: params
  });
}

// 编辑表情包
export function apiEmojiEdit(params) {
  return service({
    url: "/v1/protected/emoji/edit",
    method: "post",
    data: params
  });
}

// 删除表情包
export function apiEmojiDelete(params) {
  return service({
    url: "/v1/protected/emoji/delete",
    method: "post",
    data: params
  });
}

// 文章表情包列表
export function apiContentEmojiList(params) {
  return service({
    url: "/v1/protected/contentEmoji/page-list",
    method: "post",
    data: params
  });
}

// 添加文章表情包
export function apiContentEmojiAdd(params) {
  return service({
    url: "/v1/protected/contentEmoji/add",
    method: "post",
    data: params
  });
}

// 编辑文章表情包
export function apiContentEmojiEdit(params) {
  return service({
    url: "/v1/protected/contentEmoji/edit",
    method: "post",
    data: params
  });
}

// 删除文章表情包
export function apiContentEmojiDelete(params) {
  return service({
    url: "/v1/protected/contentEmoji/delete",
    method: "post",
    data: params
  });
}

// 为某个帖子表情[取消]点赞
export function apiContentEmojiLike(params) {
  return service({
    url: "/v1/contentEmoji/like",
    method: "post",
    data: params
  });
}

// 评论区广告图列表
export function apiAdExtPageList(params) {
  return service({
    url: "/v1/protected/adExt/page-list",
    method: "post",
    data: params
  });
}

// 添加评论区广告图
export function apiAdExtAdd(params) {
  return service({
    url: "/v1/protected/adExt/add",
    method: "post",
    data: params
  });
}

// 编辑评论区广告图
export function apiAdExtEdit(params) {
  return service({
    url: "/v1/protected/adExt/edit",
    method: "post",
    data: params
  });
}

// 删除评论区广告图
export function apiAdExtDelete(params) {
  return service({
    url: "/v1/protected/adExt/delete",
    method: "post",
    data: params
  });
}

// 游戏广告查询
export function apiAdExtQueryAd() {
  return service({ url: "/v1/adExt/queryAd", method: "get" });
}
